import React from 'react';
import { Grid } from './css';
import DigitalButton from './digitalButton';
import AmazonButton from './amazonButton';

export default function Tjenester() {
	return (
		<div>
			<Grid>
				<div className="grids">
					<div className="digital" id="tjenester">
						<DigitalButton />
					</div>
					<div />
				</div>
				<div className="grids">
					<div />
					<div className="amazon">
						<AmazonButton />
					</div>
				</div>
			</Grid>
		</div>
	);
}
