import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import Footer from './globals/footer/Footer';
import NavBar from './globals/navbar';

const Layout = ({ children }) => {
	return (
		<div>
			<React.Fragment>
				<GlobalStyle />
				<NavBar />
				{children}
				<Footer />
			</React.Fragment>
		</div>
	);
};

const GlobalStyle = createGlobalStyle`
*{
	margin: 0;
	padding:0;
	box-sizing:border-box;
font-family: "Open Sans", sans-serif;
	font-style: normal;
	font-weight: normal;
	h1, h2, h4, h5, h6, p { /*reset for mobile browsers */
		font-weight: normal;
	}
}
body{
	
	font-weight: normal;
	font-style: normal;
	background: #000;
	color:#fff;
	max-width: 100%;
}
img {
	max-width: 100%;
}
h3{
	font-weight: lighter;
}
`;

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
