import React from 'react';

export default function Formss() {
	return (
		<form method="post" data-netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/Takk">
			<input type="hidden" name="bot-field" />
			<input type="hidden" name="form-name" value="contact" />
			<div>
				<input type="text" name="navn" placeholder="Fullt navn" className="form-control" required />
			</div>
			<div>
				<input type="text" name="email" placeholder="E-postadresse" className="form-control" required />
			</div>
			<div>
				<input type="text" name="mobil" placeholder="Mobilnummer" className="form-control" required />
			</div>
			<div>
				<input type="text" placeholder="Firmanavn" name="firma-navn" className="form-control" />
			</div>
			<div>
				<textarea name="melding" rows="3" placeholder="Henvendelse" className="form-control" />
			</div>
			<div data-netlify-recaptcha="true" />
			<button type="submit" className="submit">
				Send
			</button>
		</form>
	);
}
