import React, { Component } from 'react';
import { OpenButton } from './css';

export default class OpenDrinks extends Component {
	state = {
		showMessage: false
	};

	onButtonClickHandlers = () => {
		this.setState({ showMessage: !this.state.showMessage });
	};

	render() {
		return (
			<OpenButton>
				<div>
					<button className="button" onClick={this.onButtonClickHandlers}>
						<h2 className="nett">
							Nettside & <br />eCommerce
						</h2>
					</button>
					{this.state.showMessage && (
						<div className="bulletpoints right">
							<ul>
								<li>Utvikling av nettsidestrategi.</li>
								<li>Utvikling av nettbutikkstrategi.</li>
								<li>Oppsett av nettbutikk.</li>
								<li>Oppsett av landingssider.</li>
								<li>Valg av nettløsning/platform.</li>
								<li>Google Business.</li>
								<li>Storytelling.</li>
							</ul>
						</div>
					)}
				</div>
				<div className="underline2" />
			</OpenButton>
		);
	}
}
