import React, { Component } from 'react';
import { Link } from 'gatsby';

import logo from '../../../images/BeeD2.png';

import { HeaderWrapper } from './HeaderWrapper';

export default class NavbarHeader extends Component {
	render() {
		return (
			<HeaderWrapper>
				<Link className="background" to="/">
					<img className="logo1" src={logo} alt="Danebu Kongsgaard" />
				</Link>
			</HeaderWrapper>
		);
	}
}
