import styled from 'styled-components';

export const Grid = styled.div`
	.grids {
		display: grid;
		grid-template-columns: auto;
		grid-row-gap: 1rem;
		h2 {
			font-size: 3rem;
			text-transform: uppercase;
			&:hover {
				font-size: 3.2rem;
				cursor: pointer;
			}
			@media (max-width: 768px) {
				font-size: 1.8rem;
				&:hover {
					font-size: 1.9rem;
					cursor: pointer;
				}
			}
		}
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 1rem;
		}
		padding: 4rem 2rem;
		.digital {
			text-align: left;
		}
		.amazon {
			text-align: right;
		}
	}
`;

export const OpenButton = styled.div`
	background: black;
	outline: none;
	.button {
		outline: none;
		border: none;
	}
	.underline1 {
		height: 0.2rem;
		background: rgb(244 124 42);
		margin: 0.5rem auto;
		margin-top: 1.5rem;
	}
	.underline2 {
		height: 0.2rem;
		background: rgb(248 184 53);
		margin: 0.5rem auto;
		margin-top: 1.5rem;
	}
	@media (max-width: 767px) {
		font-size: 2.5rem;
	}
	h2 {
		font-size: 3rem;
		color: #fff;
		background: #000;
		text-transform: uppercase;
	}
	.bulletpoints {
		padding: 2rem;
		font-size: 1.5rem;
		line-height: 2.5rem;
		@media (max-width: 768px) {
			font-size: 1rem;
		}
	}
	.digital {
		text-align: left;
		@media (max-width: 768px) {
			font-size: 1.8rem;
			&:hover {
				font-size: 1.9rem;
				cursor: pointer;
			}
		}
	}
	.nett {
		text-align: right;
		@media (max-width: 768px) {
			font-size: 1.8rem;
			&:hover {
				font-size: 1.9rem;
				cursor: pointer;
			}
		}
	}
	li {
		list-style: none;
	}
`;

export const OssWrap = styled.div`
	margin: auto;
	background: #000;
	@media (max-width: 768px) {
		width: 100%;
	}
	.title {
		position: absolute;
		top: 2rem;
		text-align: left;
		padding-left: 2rem;
		padding-right: 2rem;
		color: #fff;
		text-transform: uppercase;
		font-size: 2rem;
		width: 50%;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	.underline1 {
		height: 0.2rem;
		background: rgb(244 124 42);
		margin: 0.5rem auto;
		margin-top: 1.5rem;
	}
	.text2 {
		position: absolute;
		text-align: left;
		color: #fff;
		font-size: 1rem;
		width: 70%;
		top: 10rem;
		padding-left: 2rem;
		padding-right: 2rem;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	.blur {
		height: 32rem;
		position: relative;
		opacity: 0.6;

		@media (max-width: 767px) {
			height: 52rem;
		}
		@media (min-width: 768px) {
			height: 40rem;
		}
		@media (min-width: 900px) {
			height: 33rem;
		}
	}
	.pic {
		background: #000;
		position: relative;
	}
	.FormControl {
		position: absolute;
		text-align: left;
		color: #fff;
		top: 10rem;
		padding-left: 2rem;

		background: transparent;

		line-height: 2rem;
		width: 50%;
		@media (max-width: 767px) {
			width: 80%;
		}
		.form-control {
			margin-bottom: 1rem;
			background: transparent;
			font-size: 1.1rem;
			color: #fff;

			width: 100%;

			line-height: 2.2rem;
			box-shadow: none;
			border: 2px solid white;
			outline: thin;
			::placeholder {
				color: #fff;
				padding: 1rem;
			}
		}
		.submit {
			border: none;
			color: #fff;
			background: #000;

			padding: 0.5rem 2rem;
			margin: 1rem;
			&:hover {
				background: #d14124;
			}
		}
	}
	.blur2 {
		position: relative;
		opacity: 0.6;
		height: 40rem;
	}
`;

export const ProsjWrap = styled.div`
	padding: 2rem;
	.title {
		text-align: left;
		color: #fff;
		text-transform: uppercase;
		font-size: 3rem;
		width: 50%;
	}
	.underline {
		height: 0.2rem;
		background: rgb(248 184 53);
		padding-left: 2rem;
		margin-top: 1.5rem;
		width: 50%;
	}
	.grids {
		display: grid;
		grid-template-columns: auto;
		grid-row-gap: 1rem;
		padding-top: 2rem;
		@media (min-width: 768px) {
			grid-template-columns: 1.5fr 1fr;
			grid-column-gap: 1rem;
		}
	}

	.text1 {
		position: absolute;
		top: 2rem;
		text-align: left;
		padding-left: 2rem;
		color: #fff;
		text-transform: uppercase;
		font-size: 2rem;
		width: 50%;
	}
	.text3 {
		position: absolute;
		top: 8rem;
		text-align: left;
		padding-left: 2rem;
		line-height: 1.2rem;
		color: #fff;

		font-size: 1rem;
		width: 80%;
	}
	li {
		list-style: none;
	}
	.text4 {
		position: absolute;
		top: 8rem;
		text-align: right;
		padding-left: 2rem;
		line-height: 1.2rem;
		color: #fff;

		font-size: 1rem;
		width: 95%;
	}
	.text2 {
		position: absolute;
		top: 2rem;
		text-align: right;
		padding-left: 2rem;
		color: #fff;
		text-transform: uppercase;
		font-size: 2rem;
		width: 95%;
	}
	.grids2 {
		display: grid;
		grid-template-columns: auto;
		grid-row-gap: 1rem;
		padding-top: 2rem;
		@media (min-width: 768px) {
			grid-template-columns: 1fr 1.5fr;
			grid-column-gap: 1rem;
		}
	}
	.blur {
		height: 20rem;
		position: relative;
		opacity: 0.5;
		@media (max-width: 767px) {
			height: 22rem;
		}
	}
	.pic {
		background: #000;
		position: relative;
	}
`;
