import React from 'react';
import { ProsjWrap } from './css';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const SINGLE_IMAGE = graphql`
	{
		danebu: file(relativePath: { eq: "Danebu.jpeg" }) {
			childImageSharp {
				fluid(maxWidth: 1000) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		lightcircle: file(relativePath: { eq: "trionew.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`;

export default function Prosjekter() {
	return (
		<StaticQuery
			query={SINGLE_IMAGE}
			render={(data) => {
				const danebu = data.danebu.childImageSharp.fluid;
				const lightcircle = data.lightcircle.childImageSharp.fluid;
				return (
					<ProsjWrap>
						<div>
							<h2 className="title" id="prosjekter">
								Prosjekter
							</h2>
							<div className="underline" />
						</div>
						<div className="grids">
							<div className="pic">
								<Img fluid={danebu} className="blur" />
								<p className="text1">
									Danebu <br />Kongsgaard
								</p>
								<div className="text3">
									<ul>
										<li>Utvikling av strategi og design, og oppsett av nettside og nettbutikk.</li>
										<li>Media planlegging.</li>
										<li>Storytelling.</li>
										<li>Utvikling av brand persona.</li>
										<li>Utvikling av strategier for sosiale medier.</li>
										<li>innholdsstrategier og -kalender.</li>
										<li>Innholdsproduksjon.</li>
									</ul>
								</div>
							</div>
							<div />
						</div>
						<div className="grids2">
							<div />
							<div className="pic">
								<Img fluid={lightcircle} className="blur" />
								<p className="text2">
									Lightcircle <br />of norway
								</p>
								<div className="text4">
									<ul>
										<li>Bygget nettbutikken som var live i 2018-2020.</li>
										<li>Konsulentvirksomhet innen digital markedsføringsstrategi.</li>
										<li>Påbegynt Amazon tilstedeværelse i Europa.</li>
										<li>Snart ansvar for Amazon i Tyskland.</li>
									</ul>
								</div>
							</div>
						</div>
					</ProsjWrap>
				);
			}}
		/>
	);
}
