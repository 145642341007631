import React from 'react';
import styled from 'styled-components';
import { styles } from '../utils';

export default function Banner({ title }) {
	return (
		<BannerWrapper>
			<h1 className="title">{title}</h1>
		</BannerWrapper>
	);
}

const BannerWrapper = styled.div`
	margin-bottom: 3rem;
	text-align: left;
	display: grid;
	grid-template-columns: auto;
	padding-left: 2rem;
	@media (min-width: 576px) {
		grid-template-columns: 1fr 2fr;
		grid-column-gap: 1rem;
	}
	@media (min-width: 768px) {
		grid-template-columns: 1fr 2fr;
	}
	text-align: left;
	.title {
		color: ${styles.colors.mainWhite};
		font-size: 3rem;
		text-transform: uppercase;
		${styles.letterSpacing({ spacing: '0.5rem' })};
		@media (max-width: 400px) {
			font-size: 2.5rem;
		}
		@media (max-width: 767px) {
			font-size: 1.5rem;
		}
	}
`;

Banner.defaultProps = {
	title: 'default title'
};
