import styled from 'styled-components';
import { styles } from '../../../utils';

export const HeaderWrapper = styled.div`
	margin: auto;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.toggle-icon {
		font-size: 1.5rem;
		color: #fff;
		cursor: pointer;
	}
	@media (min-width: 768px) {
		.toggle-icon {
			display: none;
			height: 0.001rem;
		}
		.logo2 {
			display: none;
			height: 0.0001rem;
		}
	}
	img {
		cursor: pointer;
		align-items: center;
		margin: auto;
	}
	.logo1 {
		height: 4rem;
		padding: 0.3rem;
		margin: auto;
		display: flex;
		justify-content: left;
		&:hover {
			height: 4.1rem;
		}
	}
	@media (max-width: 767px) {
		padding-top: 0.5rem;
		padding-bottom: 0;
		padding-left: 0.4rem;
		padding-right: 0.4rem;
		.logo1 {
			display: none;
			height: 0.001rem;
			padding-top: 0;
		}
	}
	.logo2 {
		height: 4rem;
	}
`;

export const NavWrapper = styled.nav`
	/*margin-top: 2rem;*/
	@media (min-width: 768px) {
		display: flex;
	}
	@media (min-height: 300px) {
		position: fixed;
		justify-content: left;
		align-items: left;
		width: 100%;
		top: 0px;
		z-index: 50;
		padding: auto;
		margin: auto;
	}
	@media (max-width: 767px) {
		margin-bottom: 0;
		padding-bottom: 0;
		justify-content: center;
	}
`;

export const NavWraps = styled.nav`
	@media (max-width: 767px) {
		display: block;
		align-items: left;
		justify-content: left;
		text-align: left;
		margin: 0 auto;
	}
	@media (min-width: 768px) {
		display: flex;
		width: 100%;
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}
`;

export const LinkWrapper = styled.ul`
	li {
		list-style-type: none;
	}

	.nav-link {
		margin: auto;
		justify-content: space-between;

		align-items: left;
		display: flex;
		text-decoration: none;
		padding: 0.5rem;
		color: #fff;
		font-weight: 500;
		text-transform: uppercase;
		cursor: pointer;
		${styles.transDefault};
		&:hover {
			cursor: pointer;

			color: rgb(248, 183, 53);
		}
	}

	@media (min-width: 767px) {
		.links {
			padding: 1.3rem 5rem 0.5rem 0.5rem;
			@media (min-width: 950px) {
				padding-right: 8.5rem;
			}
		}
	}

	margin: auto;
	justify-content: space-between;
	align-items: left;
	height: ${(props) => (props.open ? '152px' : '0px')};
	overflow: hidden;
	${styles.transObject({ time: '1s' })};
	@media (min-width: 767px) {
		height: auto;
		display: flex;
		margin: 0 auto;
		.nav-link:hover {
			cursor: pointer;
		}
	}
`;
