import React, { Component } from 'react';
import NavbarHeader from './NavbarHeader';
import NavbarLinks from './NavbarLinks';

import NavHeadMob from './NavHeadMob';
import { NavWrapper, NavWraps } from './HeaderWrapper';

export default class navbar extends Component {
	state = {
		navbarOpen: false
	};
	handleNavbar = () => {
		this.setState(() => {
			return { navbarOpen: !this.state.navbarOpen };
		});
	};

	constructor(props) {
		super(props);

		this.listener = null;
		this.state = {
			status: 'top'
		};
	}

	componentDidMount() {
		this.listener = document.addEventListener('scroll', (e) => {
			var scrolled = document.scrollingElement.scrollTop;
			if (scrolled >= 100) {
				if (this.state.status !== 'NavWrapper') {
					this.setState({ status: 'NavWrapper' });
				}
			} else {
				if (this.state.status !== 'top') {
					this.setState({ status: 'top' });
				}
			}
		});
	}

	componentDidUpdate() {
		document.removeEventListener('scroll', this.listener);
	}

	render() {
		return (
			<NavWrapper
				style={{
					backgroundColor: this.state.status === 'top' ? 'rgba(0, 0, 0, 0.7)' : '#000',
					color: this.state.status === 'top' ? '#D6D2C4' : '#4E4934',
					position: 'fixed',
					transition: '1000ms ease'
				}}
			>
				<NavHeadMob handleNavbar={this.handleNavbar} />
				<NavWraps>
					<NavbarHeader />
					<NavbarLinks className="navClosed links" navbarOpen={this.state.navbarOpen} />
				</NavWraps>
			</NavWrapper>
		);
	}
}
