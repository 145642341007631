import React, { Component } from 'react';
import { OpenButton } from './css';

export default class OpenDrinks extends Component {
	state = {
		showMessage: false
	};

	onButtonClickHandlers = () => {
		this.setState({ showMessage: !this.state.showMessage });
	};

	render() {
		return (
			<OpenButton>
				<div>
					<button className="button" onClick={this.onButtonClickHandlers}>
						<h2 className="digital">
							Digitale <br />Markedsførings- <br />strategier
						</h2>
					</button>
					{this.state.showMessage && (
						<div className="bulletpoints">
							<ul>
								<li>Media planlegging.</li>
								<li>Utvikling av brand persona.</li>
								<li>Storytelling.</li>
								<li>Strategiutvikling for sosiale medier.</li>
								<li>Innholdsstrategi.</li>
							</ul>
						</div>
					)}
				</div>
				<div className="underline1" />
			</OpenButton>
		);
	}
}
