import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const query = graphql`
	{
		site {
			siteMetadata {
				author
				siteDesc: description
				siteImage: image
				siteUrl
				siteTitle: title
			}
		}
	}
`;

const SEO = ({ description, image }) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);
	const { siteDesc, siteTitle, siteUrl, siteImage } = site.siteMetadata;
	return (
		<Helmet htmlAttributes={{ lang: 'no' }} title={`${siteTitle}`}>
			<meta name="description" content={description || siteDesc} />
			<meta property="og:siteUrl" name="url" content={`${siteUrl}${pathname}`} />
			<meta property="og:image" name="image" content={`${siteUrl}${image}` || `${siteImage}`} />
		</Helmet>
	);
};

export default SEO;
