import styled from 'styled-components';

export const FooterInfo = styled.div`
	background: #000;
	color: #fff;
	padding: 2rem;
	margin: auto;
	display: grid;
	grid-template-columns: auto;
	grid-row-gap: 1rem;
	padding-bottom: 4rem;
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 1rem;
	}

	.powered {
		padding: 0.2rem;
		justify-content: center;
		display: flex;
		text-align: left;
		@media (max-width: 767px) {
			justify-content: center;
			display: flex;
			text-align: center;
		}
	}
	.rights {
		text-align: right;

		padding: 0.2rem;
		padding-right: 2rem;
		@media (max-width: 767px) {
			justify-content: center;
			text-align: center;
		}
	}
	img {
		height: 4rem;
		padding-left: 2rem;
		justify-content: left;
		align-items: left;
		margin: auto;
		@media (max-width: 767px) {
			justify-content: center;
			text-align: center;
			display: flex;
			padding-left: 0.2rem;
		}
	}
`;
