import React from 'react';
import styled from 'styled-components';
import { styles } from '../utils';

export function Title({ title, message }) {
	return (
		<TitleWrapper>
			<h3 className="message">{message}</h3>
			<h1 className="title">{title}</h1>
			<div className="underline" />
		</TitleWrapper>
	);
}

Title.defaultProps = {
	message: 'our message',
	title: 'our title'
};

const TitleWrapper = styled.div`
	text-align: center;
	.message {
		font-size: 2rem;
		color: ${styles.colors.mainGrey2};
		${styles.textNice};
		${styles.letterSpacing({ spacing: '0.3rem' })};
	}
	.title {
		${styles.letterSpacing({ spacing: '0.3rem' })};
		font-size: 2rem;
	}
	.underline {
		width: 5rem;
		height: 0.2rem;
		background: ${styles.colors.mainGrey2};
		margin: 0.5rem auto;
	}
`;
