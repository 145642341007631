import React, { Component } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { LinkWrapper } from './HeaderWrapper';

export default class NavbarLinks extends Component {
	state = {
		links: [
			{
				id: 0,
				path: '/#tjenester',
				name: 'Tjenester'
			},
			{
				id: 1,
				path: '/#oss',
				name: 'oss'
			},
			{
				id: 2,
				path: '/#prosjekter',
				name: 'Prosjekter'
			},
			{
				id: 3,
				path: '/#kontakt',
				name: 'Kontakt'
			}
		]
	};
	render() {
		console.log(this.props.navbarOpen);
		return (
			<LinkWrapper open={this.props.navbarOpen}>
				{this.state.links.map((item) => {
					return (
						<li key={item.id}>
							<AnchorLink to={item.path} className="nav-link links homeNav">
								{item.name}
								{item.img}
							</AnchorLink>
						</li>
					);
				})}
			</LinkWrapper>
		);
	}
}
