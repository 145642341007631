import React from 'react';
import { OssWrap } from './css';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import Formss from './kontaktForm';

const SINGLE_IMAGE = graphql`
	{
		view: file(relativePath: { eq: "view.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1400) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`;

export default function Oss() {
	return (
		<StaticQuery
			query={SINGLE_IMAGE}
			render={(data) => {
				const view = data.view.childImageSharp.fluid;
				return (
					<OssWrap>
						<div className="pic">
							<Img fluid={view} className="blur2" />
							<div className="title">
								<h2 id="kontakt">Kontakt</h2>
								<div className="underline1" />
							</div>
							<div className="FormControl">
								<Formss />
							</div>
						</div>
					</OssWrap>
				);
			}}
		/>
	);
}
