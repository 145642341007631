import React from 'react';
import CookieConsent from 'react-cookie-consent';

const Cookie = () => {
	return (
		<CookieConsent
			location="bottom"
			buttonText="Godta"
			cookieName="BeeDigital"
			style={{ background: 'rgba(0, 0, 0, 0.7)', color: '#fff', fontSize: '12px' }}
			buttonStyle={{ color: '#000', background: '#fff', border: 'none' }}
			expires={150}
		>
			BeeDigital bruker informasjonskapsler for å sikre best mulig service.
		</CookieConsent>
	);
};

export default Cookie;
