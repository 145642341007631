import React from 'react';
import { OssWrap } from './css';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

const SINGLE_IMAGE = graphql`
	{
		vertskap: file(relativePath: { eq: "vertskap.jpeg" }) {
			childImageSharp {
				fluid(maxWidth: 1400) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`;

export default function Oss() {
	return (
		<StaticQuery
			query={SINGLE_IMAGE}
			render={(data) => {
				const vertskap = data.vertskap.childImageSharp.fluid;
				return (
					<OssWrap>
						<div className="pic">
							<Img fluid={vertskap} className="blur" />
							<div className="title">
								<h2 id="oss">Om oss</h2>
								<div className="underline1" />
							</div>
							<div className="text2">
								<p>
									Akkurat som biene er uvurderlig for mange av matplantene våre, er digital
									tilstedeværelse uvurderlig for dagens bedrifter.
								</p>
								<br />
								<p>
									BeeDigital AS er en konsulentvirksomhet som hjelper bedrifter med å komme i gang med
									eller forbedre digitale strategier og nettsted. Fokuset ligger på kreative, men
									praktisk enkle løsninger som skreddersys for den individuelle bedriften.
								</p>
								<br />
								<p>
									Hver dag bombaderes man av markedsføringsstøy fra alle områder. Skal man skinne
									igjennom dette støyet, må løsningene være annerledes og iøyefallende. Det må være en
									historie brukerne ønsker å høre mer om og det må være riktig for akkurat dem.
									BeeDigital hjelper deg med å skinne gjennom støyet.
								</p>
								<br />
								<p>
									Menneskene bak BeeDigital er Pascal Gross og Malene Tuv Sandvik. <br />
									Pascal har Bachelor og Master fra England i International Management. Etter studiene
									har han fordypet seg i og mestret Adobe, nettbutikk, design, fotografering og
									videoinnspilling. Hans styrke er design og innholdsproduksjon.
									<br />
									Malene har Bachelor i Business and Mangement fra England og Master i Digital
									Marketing fra Tyskland. Hennes styrke er strategier, storytelling og
									nettsidebygging.
								</p>
							</div>
						</div>
					</OssWrap>
				);
			}}
		/>
	);
}
