import React, { Component } from 'react';
import { Link } from 'gatsby';
import logo2 from '../../../images/BeeD2.png';
import { FaBars } from 'react-icons/fa';

import { HeaderWrapper } from './HeaderWrapper';

export default class NavHeadMob extends Component {
	render() {
		const { handleNavbar } = this.props;
		return (
			<HeaderWrapper>
				<Link to="/">
					<img className="logo2" src={logo2} alt="Danebu Kongsgaard" />
				</Link>
				<FaBars
					className="toggle-icon"
					onClick={() => {
						handleNavbar();
					}}
				/>
			</HeaderWrapper>
		);
	}
}
