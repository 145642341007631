import React, { Component } from 'react';
import logo from '../../../images/BeeD2.png';
import { FooterInfo } from '../css';
import { Link } from 'gatsby';

export default class Footer extends Component {
	render() {
		return (
			<footer>
				<FooterInfo>
					<div>
						<Link to="/">
							<img src={logo} alt="BeeDigital" />
						</Link>
					</div>
					<div>
						<p className="powered">BeeDigital AS</p>
						<p className="powered">918 819 746 </p>
						<p className="powered">Danebuvegen 625</p>
						<p className="powered">2910 Aurdal</p>
					</div>

					<div>
						<p className="rights">copyright &copy; 2021 BeeDigital AS</p>
						<p className="rights">Powered by BeeDigital AS</p>
					</div>
				</FooterInfo>
			</footer>
		);
	}
}
