import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import img from '../images/hovedbilde.jpg';
import { HomeHeader, Banner } from '../utils';
import Tjenester from '../components/home/tjenester';
import Oss from '../components/home/oss';
import Prosjekter from '../components/home/prosjekter';
import Kontakt from '../components/home/kontakt';
import Cookies from '../components/cookie';

const IndexPage = () => (
	<Layout>
		<Seo
			description="Et fjellhotell ca 2,5t fra Oslo midt i Valdres sitt nydelige fjellandskap. Et sted der du kan lande og lade, nyte vakker natur og nydelige matopplevelser fra lokale råvarer."
			image={img}
		/>
		<HomeHeader img={img}>
			<Banner title="digitale markedsførings-strategier" />
		</HomeHeader>
		<Tjenester />
		<Oss />
		<Prosjekter />
		<Kontakt />
		<Cookies />
	</Layout>
);

export default IndexPage;
